<template>
  <v-list dense v-if="tokenName" nav>
    <profile-master></profile-master>
    <profile-action></profile-action>
    <v-divider></v-divider>
    <component
      v-for="(node, index) in getMenuData()"
      :is="getComponent(node)"
      :key="index"
      :node="node">
    </component>
  </v-list>
</template>

<script lang="babel" type="text/babel">
import {mapGetters} from 'vuex'
export default {
  props: {
    value: {
      type: Boolean,
    }
  },
  methods: {
    getMenuData() {
      const moduleMenuData = window.eagleLodash.cloneDeep(window.bootstrap.getMenu('admin')) || []
      const moduleMenuAdminTopData = window.eagleLodash.cloneDeep(window.bootstrap.getMenu('adminTop')) || []
      const moduleMenuAdminBottomData = window.eagleLodash.cloneDeep(window.bootstrap.getMenu('adminBottom')) || []
      const menu = [
        // 後台進入頁
        {
          label: this.$t('page.admin.entry'),
          icon: 'fa fa-tachometer-alt',
          route: {
            name: 'admin.entry'
          },
        },
        ...moduleMenuAdminTopData,
        ...moduleMenuData,
        ...moduleMenuAdminBottomData,
      ]
      return this.$helper.getComputedLinkList(menu)
    },
    getComponent(node) {
      if(Array.isArray(node.group)) return 'menuGroup'
      return 'menuLink'
    },
  },
  computed: {
    ...mapGetters({
      tokenName: 'token/name',
      isMaintainer: 'token/isMaintainer',
      avatar: 'token/avatar',
    })
  },
  components: {
    profileMaster: () => import('./profileMaster.vue'),
    profileAction: () => import('./profileAction.vue'),
    menuGroup: () => import('./menu/group'),
    menuLink: () => import('./menu/link'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
